<template>
    <v-container>
        <v-row class="text-h4 font-weight-bold primary--text text--lighten-1">
            <v-col cols="12" class="text-center py-2 mb-2">
                Plan Actual
            </v-col>
        </v-row>
        <v-row class="mt-2 mb-5">
            <v-col>
                <v-card>
                    <v-row>
                        <v-col cols="9" class="py-2">
                            <v-card-text class="text-body-1 font-weight-medium black--text text-center py-1">
                                {{ celular }}
                            </v-card-text>
                        </v-col>
                        <v-col cols="2" class="py-2">
                            <v-card-text class="text-body-1 font-weight-medium black--text text-right py-1">
                                <v-icon>mdi-account</v-icon>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-btn 
            class="mt-2 mb-5" 
            block 
            dark
            color="primary"
            to="Recargas"
            >
            COMPRAR PAQUETE
        </v-btn>
                    
        <!-- <v-btn 
            class="secondary my-3" 
            block 
            dark
            to="Portar"
            disabled
            >PORTA TU NUMERO</v-btn> -->
        
        <v-row>
            <v-col class="py-1 my-1" cols="12" v-for="(item, i) in items2" :key="i">
                <v-card>
                    <v-card-title class="primary--text text--lighten-1 title pt-4 pb-6">{{ item.offeringId }}</v-card-title>
                    <v-row>
                        <v-col cols="6" class="py-1">
                            <v-card-subtitle class="py-1 caption">
                                <v-icon color="primary lighten-1">mdi-phone</v-icon> <span class="primary--text text--lighten-1">Llamadas:</span> <br> {{ item.min.initialAmt - item.min.unusedAmt }} min / ilimitado
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="6" class="py-1">
                            <v-card-subtitle class="py-1 caption">
                                <v-icon color="primary lighten-1">mdi-email</v-icon> <span class="primary--text text--lighten-1">Mensajes:</span> <br> {{ item.sms.initialAmt - item.sms.unusedAmt }} sms / ilimitado
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="6" class="py-1">
                            <v-card-subtitle class="py-1 caption">
                                <v-icon color="primary lighten-1">mdi-cloud-download</v-icon> <span class="primary--text text--lighten-1">Datos:</span> <br> {{ (parseInt(item.dat.initialAmt) - parseInt(item.dat.unusedAmt)) > 1000 ? ( (parseInt(item.dat.initialAmt) - parseInt(item.dat.unusedAmt))/1000) + ' Gb' : (parseInt(item.dat.initialAmt) - parseInt(item.dat.unusedAmt)) + ' Mb' }} / {{ parseInt(item.dat.initialAmt) > 1000 ? (parseInt(item.dat.initialAmt)/1000) + ' Gb' : item.dat.initialAmt + ' Mb'}}
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="6" class="py-1">
                            <v-card-subtitle class="pt-1 pb-3 caption">
                                <v-icon color="primary lighten-1">mdi-calendar-clock</v-icon> <span class="primary--text text--lighten-1">Vencimiento:</span> <br> {{ item.expireDate }}
                            </v-card-subtitle>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <!-- <v-row class="mt-0">
            <v-col v-for="(item, index) in items" :key="index" cols="12">
                <v-card class="text-center">

                    <div class="pt-3 blue-grey lighten-5">
                        <v-icon color="primary lighten-1">{{ item.icono }}</v-icon>
                    </div>
                    <v-card-text class="primary--text text--lighten-1 subtitle-1 pt-1 pb-4 blue-grey lighten-5">
                        {{ item.titulo }}
                    </v-card-text>

                    <v-card-text class="py-2" v-if="item.unused !== 0">
                        {{ item.unused }} {{ item.info }} / {{ item.total }} {{ item.info }}
                    </v-card-text>
                    <v-card-text class="py-2" v-else>
                        Agotado
                    </v-card-text>
                    
                </v-card>
            </v-col>
        </v-row> -->
        
        <v-dialog v-model="dialogo">
            <v-stepper v-model="pasoActual" elevation="0">
                <v-stepper-items>
                    <v-stepper-content v-for="n in pasos" :key="n" :step="n" class="px-0">
                        <v-card elevation="0">
                            <v-card-subtitle class="text-h4 text-center pt-6 primary--text">{{ promociones[n-1].Titulo }}</v-card-subtitle>
                            <v-card-text class="text-center secondary--text">{{ promociones[n-1].Mensaje }}</v-card-text>
                            <v-card-actions>
                                <v-spacer>
                                    <v-btn text color="secondary" @click="pasoActual--">Atras</v-btn>
                                    <v-btn text color="primary" @click="SiguientePromocion">{{ BtnPromo }}</v-btn>
                                </v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    data(){
        return{
            // items: [],
            items:[
                { titulo: 'Llamadas', info: 'min', total: 0, unused: 0, icono: 'mdi-phone' },
                { titulo: 'Mensajes', info: 'sms', total: 0, unused: 0, icono: 'mdi-email' },
                { titulo: 'Datos', info: 'Gbs', total: 0, unused: 0, icono: 'mdi-cloud-download' },
            ],
            items2:[],
            secuencias:[],
            token: '',
            celular: '',
            dialogo: false,
            pasoActual: 1,
            promociones: []
        }
    },
    mounted(){
        try{
            this.promociones = JSON.parse(localStorage.getItem('promos'))
        }catch{
            this.promociones = []
        }
    },
    created(){
        this.token = localStorage.getItem('token')
        this.celular = localStorage.getItem('celular')
        const body = {
            token: this.token,
            numero: this.celular
        }
        const params = {
            body: JSON.stringify(body),
            method: 'post'
        }
        async function getPerfil(){
            var resp = await fetch('https://maifon.mx/api/getPerfil.php', params)
            var data = await resp.json()
            return data
        }
        getPerfil().then(d=>{
            if (d.responseSubscriber) {

                this.items.forEach(element => {
                    element.total = 0
                    element.unused = 0
                });

                d.responseSubscriber.freeUnits.forEach(elemento=>{
                    elemento.detailOfferings.forEach(e=>{
                        if (!this.secuencias.includes(e.purchaseSecuence)) {
                            this.secuencias.push(e.purchaseSecuence)
                            this.items2.push({
                                effectiveDate: e.effectiveDate,
                                expireDate: e.expireDate.substr(6,2) + '/' + e.expireDate.substr(4,2) + '/' + e.expireDate.substr(0,4),
                                offeringId: e.offeringId,
                                purchaseSecuence: e.purchaseSecuence,
                                min: {
                                    initialAmt: elemento.name.indexOf('_Min_') >= 0 ? e.initialAmt : 0,
                                    unusedAmt: elemento.name.indexOf('_Min_') >= 0 ? e.unusedAmt : 0
                                },
                                sms: {
                                    initialAmt: elemento.name.indexOf('_SMS_') >= 0 ? e.initialAmt : 0,
                                    unusedAmt: elemento.name.indexOf('_SMS_') >= 0 ? e.unusedAmt : 0
                                },
                                dat: {
                                    initialAmt: elemento.name.indexOf('_Data_') >= 0 || elemento.name.indexOf('FreeData') >= 0 ? e.initialAmt : 0,
                                    unusedAmt: elemento.name.indexOf('_Data_') >= 0 || elemento.name.indexOf('FreeData') >= 0 ? e.unusedAmt : 0
                                }
                            })
                        }else{
                            const idx = this.items2.findIndex(x => x.purchaseSecuence === e.purchaseSecuence)
                            this.items2[idx].min.initialAmt = elemento.name.indexOf('_Min_') >= 0 ? parseInt(this.items2[idx].min.initialAmt) + parseInt(e.initialAmt) : this.items2[idx].min.initialAmt
                            this.items2[idx].min.unusedAmt = elemento.name.indexOf('_Min_') >= 0 ? parseInt(this.items2[idx].min.unusedAmt) + parseInt(e.unusedAmt) : this.items2[idx].min.unusedAmt

                            this.items2[idx].sms.initialAmt = elemento.name.indexOf('_SMS_') >= 0 ? parseInt(this.items2[idx].sms.initialAmt) + parseInt(e.initialAmt) : this.items2[idx].sms.initialAmt
                            this.items2[idx].sms.unusedAmt = elemento.name.indexOf('_SMS_') >= 0 ? parseInt(this.items2[idx].sms.unusedAmt) + parseInt(e.unusedAmt) : this.items2[idx].sms.unusedAmt

                            this.items2[idx].dat.initialAmt = elemento.name.indexOf('_Data_') >= 0 || elemento.name.indexOf('FreeData') >= 0 ? parseInt(this.items2[idx].dat.initialAmt) + parseInt(e.initialAmt) : this.items2[idx].dat.initialAmt
                            this.items2[idx].dat.unusedAmt = elemento.name.indexOf('_Data_') >= 0 || elemento.name.indexOf('FreeData') >= 0 ? parseInt(this.items2[idx].dat.unusedAmt) + parseInt(e.unusedAmt) : this.items2[idx].dat.unusedAmt
                        }
                    })
                })

                d.responseSubscriber.freeUnits.forEach(elemento=>{
                    if (elemento.name.indexOf('FU_Min_') >= 0) {
                        this.items[0].total = parseInt(this.items[0].total) + parseInt(elemento.freeUnit.totalAmt)
                        this.items[0].unused = parseInt(this.items[0].unused) + parseInt(elemento.freeUnit.unusedAmt)
                        
                    }else if (elemento.name.indexOf('FU_SMS_') >= 0) {
                        this.items[1].total = parseInt(this.items[1].total) + parseInt(elemento.freeUnit.totalAmt)
                        this.items[1].unused = parseInt(this.items[1].unused) + parseInt(elemento.freeUnit.unusedAmt)
                        
                    }else if (elemento.name.indexOf('FU_Data_') >= 0) {
                        this.items[2].total = parseInt(this.items[2].total) + parseInt(elemento.freeUnit.totalAmt)
                        this.items[2].unused = parseInt(this.items[2].unused) + parseInt(elemento.freeUnit.unusedAmt)
                        
                    }
                })

                const esMBB = this.items2.find((e)=>e.dat.initialAmt>0 && e.dat.unusedAmt>0 && e.min.initialAmt>0 && e.min.unusedAmt>0 && e.sms.initialAmt>0 && e.sms.unusedAmt>0);
                if (!esMBB) {
                  d.responseSubscriber.freeUnits.forEach((elemento=>{
                    elemento.detailOfferings.forEach(e=>{
                      const idx = this.items2.findIndex(x => x.purchaseSecuence === e.purchaseSecuence);

                      this.items2[idx].dat.initialAmt = parseInt(this.items2[idx].dat.initialAmt) + parseInt(e.initialAmt);
                      this.items2[idx].dat.unusedAmt = parseInt(this.items2[idx].dat.unusedAmt) + parseInt(e.unusedAmt);
                    })
                    // this.items[2].total = parseInt(this.items[2].total) + parseInt(elemento.freeUnit.totalAmt)
                    // this.items[2].unused = parseInt(this.items[2].unused) + parseInt(elemento.freeUnit.unusedAmt)
                  }))
                }
                
                this.getNombrePlan()
            }
        })

        this.$emit('muestra', false)
    },
    methods:{
        SiguientePromocion: function(){
            if (this.pasoActual!==this.promociones.length) {
                this.pasoActual++
            }else{
                this.dialogo = false
                this.promociones = []
                localStorage.setItem('promos', '')
            }
        },
        async setNombrePlan(elemento, index){
            const body = {
                opcion: 37,
                idAltan: elemento.offeringId
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }
            const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php', params)
            const data = await response.json()
            if (data.length > 0) {
                this.items2[index].offeringId = data[0].Nombre
                return data[0].Nombre
            }else{
                this.items2[index].offeringId = 'Nombre no encontrado'
                return 'Nombre no encontrado'
            }
        },
        getNombrePlan(){
            this.items2.forEach((elemento, index)=>{
                this.setNombrePlan(elemento, index)
            })
        }
    },
    computed:{
        BtnPromo: function(){
            return this.pasoActual === this.promociones.length ? 'Cerrar' : 'Siguiente'
        },
        pasos: function(){
            if (this.promociones.length > 0) {
                this.dialogo = true
            }else{
                this.dialogo = false
            }
            return this.promociones.length
        }
    }
}
</script>